import { Card, Col, List, Row, Tooltip, message, theme, Select } from 'antd';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import React from 'react';
import StaticCard from '../common/StaticCard';
import { Bar ,Pie ,Doughnut} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from 'chart.js';
import { URL_DASHBOARD } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';
import _ from "lodash";
import { AUBBER_AGE, FARMER_STATUS, FARMER_TYPE, LANGUAGE } from '../../constants/string';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import { BLACK_COLOR, DARK_GRAY_COLOR, PRIMARY_COLOR, WHITE_COLOR_BG } from '../../constants/color';
import PlantationTypeCard from './PlantationTypeCard';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend
);

export default function DashboardOfficerView() {
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const [typeFilter, setTypeFilter] = React.useState('');
  const [dataStatusFocus, setDataStatusFocus] = React.useState(null);
  const [dataPlantationFocus, setDataPlantationFocus] = React.useState(null);
  const [dataFarmerFocus, setDataFarmerFocus] = React.useState(null);
  const { t, i18n } = useTranslation();

  const {
    token: { colorWarning, colorInfo, colorError }
  } = theme.useToken();

  const dataColor = (index ,total)=>{
    const startHue = 135
    const result = (startHue + (360*index/total))%360
    const color1 = (`hsl(${result}, 85%, 50%, 0.8)`)
    return color1
  }

  const dataColorฺBorder = (index, total)=>{
    const startHue = 135
    const result = (startHue + (360*index/total))%360
    const color1 = (`hsl(${result}, 80%, 40%)`)
    return color1
  }

  const fetchDataStatus = async () => {
    try {
    const response = await GET(`${URL_DASHBOARD.STATUS_FOCUS}`, {traceability : typeFilter})
    setDataStatusFocus(response.data);
    } catch (error) {
      message.error(error.errorMessage);
    }
  };

  const fetchDataPlantation = async () => {
    try {
    const response = await GET(`${URL_DASHBOARD.PLANTATION_FOCUS}`, {traceability : typeFilter})
    setDataPlantationFocus(response.data)
    } catch (error) {
      message.error(error.errorMessage);
    }
  };
  const fetchDataFarmer = async () => {
    try {
    const response = await GET(`${URL_DASHBOARD.FARMER_FOCUS}`, {traceability : typeFilter})
    setDataFarmerFocus(response.data)
    } catch (error) {
      message.error(error.errorMessage);
    }
  };

  const styleCare = {
    boxShadow: '1px 1px 6px #DADADA',
    borderRadius: 0,
    height: '300px',
    backgroundColor:'#FDFCFC'
  };

  const dataBarFarmer = {
    labels: [t("dashboard.number_of_farmer")],
    datasets: [
      {
        label: t("dashboard.wait_approval"),
        data: [dataStatusFocus && dataStatusFocus.status.waiting.farmer],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: [
        'rgb(54, 162, 235)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
      {
        label: t("dashboard.approved"),
        data: [dataStatusFocus && dataStatusFocus.status.approve.farmer],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgb(75, 192, 192)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
    ],
  };
  const dataBarPlantation = {
    labels: [t("dashboard.number_of_plantations")],
    datasets: [
      {
        label: t("dashboard.wait_approval"),
        data: [dataStatusFocus && dataStatusFocus.status.waiting.plantation],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: [
        'rgb(54, 162, 235)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
      {
        label: t("dashboard.approved"),
        data: [dataStatusFocus && dataStatusFocus.status.approve.plantation],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgb(75, 192, 192)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
   
    ],
  };

  const dataBarArea= {
    labels : [t("dashboard.rubber_plantation_area")],
    datasets: [
      {
        label: t("dashboard.wait_approval"),
        data: [dataStatusFocus && dataStatusFocus.status.waiting.area],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: [
        'rgb(54, 162, 235)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
      {
        label: t("dashboard.approved"),
        data: [dataStatusFocus && dataStatusFocus.status.approve.area],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgb(75, 192, 192)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
  
      
    ],
  };

  const dataBarFarmerArea = {
    labels : [t("dashboard.number_of_farmer")],
    datasets: [
      {
        label: t("dashboard.farmers_in_the_area") ,
        data: dataFarmerFocus && [dataFarmerFocus.area.local],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: [ 'rgb(54, 162, 235)' ],
        borderWidth: 1,
        barThickness: 35,
      },
      {
        label: t("dashboard.farmers_outside_the_area"),
        data: dataFarmerFocus && [dataFarmerFocus.area.foreign],
        backgroundColor: [ 'rgba(75, 192, 192, 0.2)' ],
        borderColor: [ 'rgb(75, 192, 192)' ],
        borderWidth: 1,
        barThickness: 35,
      },
      
    ],
  };

  // Style of chart options
  const barOptionStyles = (title) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: title,
          font: { size: 18 },
        },
      },
      maintainAspectRatio: false, // Set to false to allow custom width and height
      width: 'auto', // Set your desired width
      height: '200px', // Set your desired height
    };
  }

  const optionsBarFarmerArea= {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: `${t("dashboard.farmers_in_the_area")} / ${t("dashboard.farmers_outside_the_area")}`,
        font: {
          size: 18, 
        },
      },
    },
    maintainAspectRatio: false, // Set to false to allow custom width and height
    width: 'auto', // Set your desired width
    height: '200px', // Set your desired height
  };

  const pieChartDataProvinces  = {
    labels: dataPlantationFocus && dataPlantationFocus.provinces.map((item) => [`${item.province === null ? t("unknown_province") : i18n.language === LANGUAGE.TH ? item.province : item.province_en} - ${item.percent}%`]),
    datasets: [
      {
        data: dataPlantationFocus && dataPlantationFocus.provinces.map((item) => item.amount),
        backgroundColor: dataPlantationFocus && dataPlantationFocus.tree_types.map((item, index) => dataColor(index, dataPlantationFocus.tree_types.length)),
        borderColor: dataPlantationFocus && dataPlantationFocus.tree_types.map((item, index) => dataColorฺBorder(index, dataPlantationFocus.tree_types.length)),
        borderWidth: 1
      }]
  };

  const pieChartDataRubberAge = {
    labels: [`${t("plots_younger_than_7_years")}  ${dataPlantationFocus && dataPlantationFocus.age.lt_7}%`,
             `${t("plots_aged_7_24_years")}  ${dataPlantationFocus && _.get(dataPlantationFocus, 'age.7_24', null)}%`,
             `${t("plots_older_than_24_years")}  ${dataPlantationFocus && dataPlantationFocus.age.gt_24} %`],
    datasets: [
      {
        data: [ dataPlantationFocus && 
          dataPlantationFocus && dataPlantationFocus.age.lt_7,
          dataPlantationFocus && _.get(dataPlantationFocus, 'age.7_24', null),
          dataPlantationFocus && dataPlantationFocus.age.gt_24,
        ],
        backgroundColor: [
          'rgba(255, 159, 64, 0.8)',//สีน้ำส้ม//
          'rgba(54, 162, 235, 0.8)',//สีน้ำน้ำเงิน//
          'rgba(255, 99, 132, 0.8)',//สีแดง//
        ],
        borderColor: [
          'rgb(255, 159, 64)',//สีน้ำส้ม//
          'rgb(54, 162, 235)',//สีน้ำน้ำเงิน//
          'rgb(255, 99, 132)',//สีแดง//  
        ],
        borderWidth: 1
      }]
  };

  const pieChartDataRubber  = {
    labels: dataPlantationFocus && dataPlantationFocus.tree_types.map((item)=>[`${item.name} - ${item.amount}%`]),
    datasets: [
      {
        data: dataPlantationFocus && dataPlantationFocus.tree_types.map((item)=>item.amount),
        backgroundColor: dataPlantationFocus && dataPlantationFocus.tree_types.map((item, index) => dataColor(index, dataPlantationFocus.tree_types.length)),
        borderColor: dataPlantationFocus && dataPlantationFocus.tree_types.map((item, index) => dataColorฺBorder(index, dataPlantationFocus.tree_types.length)),
        borderWidth: 1
      }]
  };

  const doughNutDataFarmer  = {
    labels: [`${t("farmers.owner")} - ${dataFarmerFocus && dataFarmerFocus.type.owner} ${t("people")}`,
             `${t("farmers.renter")} - ${dataFarmerFocus && dataFarmerFocus.type.renter} ${t("people")}`,
             `${t("farmers.proxy")} - ${dataFarmerFocus && dataFarmerFocus.type.proxy} ${t("people")}`],
    datasets: [
      {
        data:  dataFarmerFocus && [dataFarmerFocus.type.owner ,dataFarmerFocus.type.renter ,dataFarmerFocus.type.proxy],
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(54, 162, 235, 0.8)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(54, 162, 235)',
        ],
        borderWidth: 1
      }]
  };

  // Style of chart options
  const doughnutOptionStyles = (title) => {
    return {
      maintainAspectRatio: false,
      responsive: true,
      radius: 100,
      plugins: {
        layout: {
          padding: { left: 0 }
        },
        legend: {
          position: isOnMobile ? 'bottom' : 'right',
          text: { size: 16 }, // Set your desired legend font size
        },
        title: {
          display: true,
          text: title,
          font: { size: 18 },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.label ;
            }
          }
        }
      },
    };
  }

  React.useEffect(() => {
    fetchDataStatus();
    fetchDataPlantation();
    fetchDataFarmer();
    
  },[typeFilter])

  return (
    <>
    <Row gutter={16}>
      <Col md={24} sm={24} xs={24} >
        <PlantationTypeCard 
          style={{marginBottom : 20}}
          title={`${t("dashboard.number_of_plantations")} ( ${(dataStatusFocus && dataStatusFocus.type) ? dataStatusFocus.type.ct : 0} แปลง )`}
          explantText={t("dashboard.all_plots_number_detail")}
          valueColor={'#58D68D'}
          valueFontSize={46}
          values={(dataStatusFocus && dataStatusFocus.ct) ? [
              {info: t("farmer_status.draft"), amount: dataStatusFocus.ct.draft, color: BLACK_COLOR, rai: dataStatusFocus.ct.draft_rai},
              {info: t("farmer_status.checking"), amount: dataStatusFocus.ct.checking, color: colorWarning, rai: dataStatusFocus.ct.checking_rai},
              {info: t("farmer_status.waiting"), amount: dataStatusFocus.ct.waiting, color: colorInfo, rai: dataStatusFocus.ct.waiting_rai},
              {info: t("farmer_status.approved"), amount: dataStatusFocus.ct.approved, color: "#58D68D", rai: dataStatusFocus.ct.approved_rai},
              {info: t("farmer_status.rejected"), amount: dataStatusFocus.ct.rejected, color: colorError, rai: dataStatusFocus.ct.rejected_rai}
            ]
            : []}/>
      </Col>
    <Col xxl={12} xl={24} lg={24} md={24} xs={24}>
      <Row >
        <Col xl={6} lg={12} md={24} sm={24} xs={24} >
          <StaticCard
            title={t("male")}
            explantText={t("dashboard.gender_detail")}
            titleColor={DARK_GRAY_COLOR}
            icon={<ManOutlined style={{ fontSize: '35px', color: WHITE_COLOR_BG }} />}
            titleFontSize={18}
            width={'auto'}
            height={155}
            style={{ background: 'linear-gradient(90deg, rgba(123,211,234,1) 21%, rgba(199,242,254,1) 61%)', }}
            value={dataFarmerFocus && dataFarmerFocus.gender.male}
            info={t("person")}
            valueColor={WHITE_COLOR_BG}
            valueFontSize={35} />
        </Col>
        <Col xl={6} lg={12} md={24} sm={24} xs={24} >
          <StaticCard
            title={t("female")}
            explantText={t("dashboard.gender_detail")}
            icon={<WomanOutlined style={{ fontSize: '35px', color: WHITE_COLOR_BG }} />}
            titleColor={DARK_GRAY_COLOR}
            titleFontSize={18}
            width={'auto'}
            height={155}
            style={{ background: 'linear-gradient(90deg, rgba(242,190,209,1) 21%, rgba(251,216,228,1) 61%)', }}
            value={dataFarmerFocus && dataFarmerFocus.gender.female}
            info={t("person")}
            valueColor={WHITE_COLOR_BG}
            valueFontSize={35} />
        </Col>
        <Col xl={6} lg={12} md={24} sm={24} xs={24} >
          <StaticCard
            title={t("dashboard.average_age_of_farmers")}
            explantText={t("dashboard.farmer_age_detail")}
            precision={2}
            titleColor={DARK_GRAY_COLOR}
            titleFontSize={17}
            height={155}
            width={'auto'}
            value={dataFarmerFocus && dataFarmerFocus.average_age}
            info={t("years")}
            valueColor={'#58D68D'}
            valueFontSize={35}/>
        </Col>
        <Col xl={6} lg={12} md={24} sm={24} xs={24} >
          <StaticCard
            title={t("dashboard.average_age_of_rubber_trees")}
            explantText={t("dashboard.trees_age_detail")}
            titleColor={DARK_GRAY_COLOR}
            precision={2}
            titleFontSize={17}
            height={155}
            width={'auto'}
            value={dataPlantationFocus && dataPlantationFocus.average_age}
            info={t("years")}
            valueColor={'#58D68D'}
            valueFontSize={35} />
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} style={{marginTop : '20px'}}>
          <Card style={styleCare}>
            <Bar options={barOptionStyles(`${t("dashboard.number_of_farmer")} ${t("dashboard.waiting")} / ${t("dashboard.approved")}`)} data={dataBarFarmer} height={250} />
          </Card>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} style={{marginTop : '20px'}}>
          <Card style={styleCare}>
            <Bar options={barOptionStyles(`${t("dashboard.number_of_plantations")} ${t("dashboard.waiting")} / ${t("dashboard.approved")}`)} data={dataBarPlantation} height={250}/>
          </Card>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} style={{marginTop : '20px'}}>
          <Card style={styleCare}>
            <Bar options={barOptionStyles(`${t("dashboard.rubber_plantation_area")} ${t("dashboard.waiting")} / ${t("dashboard.approved")}`)} data={dataBarArea} height={250}/>
          </Card>
        </Col>
        <Col md={12} sm={24} xs={24} style={{marginTop : '20px', marginBottom : '20px'}}>
          <Card style={{...styleCare, height: "auto"}}>
            <Tooltip 
              placement={"top"} 
              color={"orange"} 
              title={t("dashboard.farmer_type_detail")}>
              <Doughnut options={doughnutOptionStyles(t("dashboard.farmer_type"))} data={doughNutDataFarmer} height={350}/>
            </Tooltip>
          </Card>
        </Col>
        <Col md={12} sm={24} xs={24} style={{marginTop : '20px', marginBottom : '20px'}}>
          <Card style={{...styleCare, height: "auto"}}>
            <Tooltip 
              placement={"top"} 
              color={"orange"} 
              title={t("dashboard.farmer_type_detail")}>
              <Bar options={optionsBarFarmerArea} data={dataBarFarmerArea} height={350} />
            </Tooltip>
          </Card>
        </Col>
      </Row>
    </Col>
    <Col xxl={12} xl={24} lg={24} md={24} xs={24}>
      <Row>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24} style={{marginBottom : '20px'}}>
          <Card style={{...styleCare, height: "auto"}}> 
            <Tooltip 
              placement={"top"} 
              color={"orange"} 
              title={t("dashboard.rubber_tree_varieties_detail")}>
              <Pie data={pieChartDataRubber} options={doughnutOptionStyles(t("dashboard.rubber_tree_varieties"))} height={350}/>
            </Tooltip>
          </Card>
        </Col>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24} style={{marginBottom : '20px'}}>
          <Card style={{...styleCare, height: "auto"}}> 
            <Tooltip 
              placement={"top"} 
              color={"orange"} 
              title={t("dashboard.age_of_rubber_trees_detail")}>
              <Pie data={pieChartDataRubberAge} options={doughnutOptionStyles(t("dashboard.age_of_rubber_trees"))} height={350}/>
            </Tooltip>
          </Card>
        </Col>
        </Row>
        <Row gutter={10}>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24} style={{marginBottom : '20px'}}>
              <Card style={{...styleCare, height: "auto"}}>
                <Tooltip 
                  placement={"top"} 
                  color={"orange"} 
                  title={t("dashboard.province_of_the_plantation_detail")}>
                  <Pie data={pieChartDataProvinces} options={doughnutOptionStyles(t("dashboard.province_of_the_plantation"))}  height={400} />
                </Tooltip>
              </Card>
          </Col>
            <Col xxl={12} xl={12} lg={24} md={24} xs={24}  style={{marginBottom : '20px'}}>
              <Card style={{...styleCare, height: "auto"}}>
                <div 
                  style={{ 
                    fontSize : 18, 
                    color : DARK_GRAY_COLOR, 
                    fontWeight: 'bold', 
                    textAlign: "center",
                    marginBottom: 8
                  }}>
                    {t("dashboard.province_of_the_plantation")}
                    <br></br> 
                    {t("dashboard.rubber_plantation_area_title")}</div>
                    <Row>
                      <Col span={9}>
                        <div style={{color : PRIMARY_COLOR, fontWeight : 'bolder'}}>
                          {t("province")}
                        </div>
                      </Col>
                      <Col span={5}>
                        <div style={{color : PRIMARY_COLOR, fontWeight : 'bolder'}}>
                          {t("rai")}
                        </div>
                      </Col>
                      <Col span={5}>
                        <div style={{color : PRIMARY_COLOR, fontWeight : 'bolder'}}>
                          {t("plots")}
                        </div>
                      </Col>
                      <Col span={5}>
                        <div style={{color : PRIMARY_COLOR, fontWeight : 'bolder'}}>
                          {t("farmer")}
                        </div>
                      </Col>
                  </Row>
                  <Row>
              <List
                style={{ width : '100%'}}
                dataSource={dataPlantationFocus ? dataPlantationFocus.provinces : []}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                      <Col span={9}>
                        {item.province === null ? t("unknown_province") : i18n.language === LANGUAGE.TH ? item.province : item.province_en}
                      </Col>
                      <Col span={5}>
                        {item.rai}
                      </Col>
                      <Col span={5}>
                        {item.amount}
                      </Col>
                      <Col span={5}>
                        {item.farmer}
                      </Col>
                  </List.Item>
                )}
              />
              </Row>
              </Card>
            </Col>
          </Row>
    </Col>
    </Row>
    </>

  )
}
