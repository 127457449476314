import React from 'react'
import { Layout, message } from 'antd'
import {
  UserOutlined,
  IdcardOutlined,
  FileDoneOutlined,
  RobotOutlined,
  FileProtectOutlined,
  AreaChartOutlined,
  BankOutlined,
  SettingOutlined,
  ExperimentOutlined,
  SendOutlined,
  LogoutOutlined,
  ProfileOutlined,
  CarOutlined,
  GoldOutlined,
  DatabaseOutlined,
  ShopOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PATH } from '../routes/CustomRoutes';
import "./../styles.css"
// import UserGroupView from '../views/UserGroupView';
import UserListView from '../views/UserListView';
import FactoryListView from '../views/FactoryListView';
import { useUserContext } from '../hooks/UserSettingProvider';
import MemberListView from '../views/MemberListView';
import ScaleView from '../views/ScaleView';
import CollectingCenterListView from '../views/CollectingCenterListView';
import StockView from '../components/stock/StockView';
import AdminView from '../components/collectingcenter/AdminView';
import MainMenu from '../components/common/MainMenu';
import * as _ from 'lodash';
import { OFFICER_PERMISSION, USER_TYPE } from '../constants/string';
import PlantationManagerView from '../views/PlantationsManagerView';
import DeliveryListView from '../views/DeliveryListView';
import FactoryDeliveryOrderView from '../components/factory/FactoryDeliveryOrderView';
import ScaleCardView from '../components/collectingcenter/ScaleCardView';
import FactoryShippingInstructionView from '../components/factory/FactoryShippingInstructionView';
import UserCashier from './UserCashier';
import Cookies from 'js-cookie';
import ReportsViews from '../components/officer/ReportsView';
import CustomerListView from '../components/collectingcenter/CustomerListView';
import ProductListView from '../components/collectingcenter/ProductListView';
import CarListView from '../components/collectingcenter/CarListView';
import DashboardView from '../views/DashboardView'
import DashboardTabCcView from '../views/DashboardTabCcView';
import FarmerProfileView from '../views/FarmerProfileView';
import FarmerPlantationListView from '../views/FarmerPlantationListView';
import CCSettingView from '../views/CCSettingView';
import { useTranslation } from 'react-i18next';
import FactoryDashboardView from '../views/FactoryDashboard';
import StockTabsView from '../components/stock/StockTabsView';
import FactoryCustomerView from '../components/factory/FactoryCustomerView';
import FactorySettingView from '../views/FactorySettingView';
import FactoryReportView from '../views/FactoryReportView';
import CCReportView from '../views/CCReportView';

export default function MenuScreen() {
	const { user } = useUserContext();
  const userType = user.user_type;
  const isAdmin = !user.user_type;
  const userGroup = user.groups;
	// Main menu
	const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [current, setCurrent] = React.useState(null);
  const [isCashierModalaOpen, setIsCashierModalOpen] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [cashierId, setCashierId] = React.useState(null);
  const { t, i18n } = useTranslation();

  const renderContent = () => {
    switch (current) {
      // officer
      case PATH.OFFICER_DASHBOARD.substring(1): return <div><DashboardView/></div>
      case PATH.PLANTATIONS_MANAGER.substring(1): return <PlantationManagerView />
      case PATH.OFFICER_DELIVERY_ORDER.substring(1): return <DeliveryListView />
      case PATH.COLLECTING_CENTERS.substring(1): return <CollectingCenterListView />
      case PATH.FACTORIES.substring(1): return <FactoryListView />
      case PATH.OFFICER_USERS.substring(1): return <UserListView />
      case PATH.OFFICER_REPORTS.substring(1): return <div><ReportsViews/></div>
      // case PATH.USER_GROUP.substring(1): return <UserGroupView />
      
      // collecting center
      case PATH.COLLECTING_CENTER_DASHBOARD.substring(1):return <DashboardTabCcView/>
      case PATH.SCALE.substring(1): return <ScaleView />
      case PATH.TRANSACTION.substring(1): return <ScaleCardView />
      case PATH.STOCK.substring(1): return <StockTabsView />
      case PATH.MEMBERS.substring(1): return <MemberListView />
      case PATH.CC_CUSTOMER.substring(1): return <CustomerListView />
      case PATH.CC_PRODUCT.substring(1): return <ProductListView />
      case PATH.CC_VEHICLE.substring(1): return <CarListView />
      case PATH.CC_ADMIN.substring(1): return <AdminView />
      case PATH.CC_SETTING.substring(1): return <CCSettingView />
      case PATH.CC_REPORT.substring(1): return <CCReportView />
      // case PATH.CC_LOGOUT.substring(1): return <LoginCashierModal />

      // factory
      case PATH.FACTORY_DASHBOARD.substring(1): return <FactoryDashboardView/>
      case PATH.FACTORY_DELIVERY_ORDER.substring(1): return <FactoryDeliveryOrderView/>
      case PATH.FACTORY_SHIPPING_INSTRUCTION.substring(1): return <FactoryShippingInstructionView/>
      case PATH.FACTORY_CUSTOMER.substring(1): return <FactoryCustomerView/>
      case PATH.FACTORY_PROFILE.substring(1): return <FactorySettingView/>
      case PATH.FACTORY_REPORT.substring(1): return <FactoryReportView/>

      // Farmer
      case PATH.MY_PROFILE.substring(1): return <FarmerProfileView user={user} />
      case PATH.MY_PLANTATIONS.substring(1): return <FarmerPlantationListView user={user} />
      default: return null;
    }
  }

  const menus = [
    // Dashboard
    {
      key: PATH.FACTORY_DASHBOARD.substring(1),
      icon: <FileDoneOutlined />,  
      label: t('menu.dashboard'),
      permission: (userType == USER_TYPE.FACTORY || isAdmin).toString(),
      disableMobile: true
    },
    // officer menu
    { 
      key: PATH.OFFICER_DASHBOARD.substring(1), 
      icon: <AreaChartOutlined />, 
      label: t('menu.dashboard'),
      permission: (userGroup.includes(OFFICER_PERMISSION.MANAGER) || userGroup.includes(OFFICER_PERMISSION.REPORT) || isAdmin).toString(),
      disableMobile: true
    }, 
    {
      key: PATH.PLANTATIONS_MANAGER.substring(1),
      icon: <FileProtectOutlined />,
      label: t('menu.plantations_management'),
      permission: (userGroup.includes(OFFICER_PERMISSION.MANAGER) || userType == USER_TYPE.FACTORY || isAdmin).toString(),
      disableMobile: true
    },
    {
      key: PATH.OFFICER_DELIVERY_ORDER.substring(1),
      icon: <SendOutlined />,
      label:t('menu.delivery'),
      permission: (userGroup.includes(OFFICER_PERMISSION.MANAGER) || isAdmin).toString(),
      disableMobile: true
    },
    {
      key: PATH.COLLECTING_CENTERS.substring(1), 
      icon: <RobotOutlined />, 
      label: t('menu.collecting_center'),
      permission: (userGroup.includes(OFFICER_PERMISSION.STAFF) || isAdmin).toString(),
      disableMobile: true
    },
    { 
      key: PATH.FACTORIES.substring(1), 
      icon: <BankOutlined />, 
      label: t('menu.factory'),
      permission: (userGroup.includes(OFFICER_PERMISSION.STAFF) || isAdmin).toString(),
      disableMobile: true
    },

    // collecting-center menu
    { 
      key: PATH.COLLECTING_CENTER_DASHBOARD.substring(1), 
      icon: <AreaChartOutlined />, 
      label: t('menu.dashboard'),
      permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
    }, 
    // { 
    //   key: PATH.SCALE.substring(1), 
    //   icon: <DashboardOutlined />, 
    //   label: 'บัตรชั่ง',
    //   permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
    // },
    {
      key: PATH.TRANSACTION.substring(1),
      icon: <ProfileOutlined />,
      label: t('menu.transaction'),
      labelMobile: t('menu.transaction_mobile'),
      permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
    },
    { 
      key: PATH.STOCK.substring(1), 
      icon: <ExperimentOutlined />,  
      label: t('menu.stock'),
      permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
    },
    {
      key: PATH.MEMBERS.substring(1),
      icon: <IdcardOutlined />,
      label: t('menu.member'),
      labelMobile: t('menu.member_mobile'),
      permission: (
        userType == USER_TYPE.COLLECTING_CENTER || 
        userType == USER_TYPE.FACTORY ||
        userGroup.includes(OFFICER_PERMISSION.STAFF) || 
        isAdmin
      ).toString(),
    },
    {
      key: PATH.CC_CUSTOMER.substring(1),
      icon: <UserOutlined />,
      label: t('menu.customer'),
      permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
      disableMobile: true
    },
    {
      key: PATH.CC_PRODUCT.substring(1),
      icon: <GoldOutlined />,
      label: t('menu.product'),
      permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
      disableMobile: true
    },
    {
      key: PATH.CC_VEHICLE.substring(1),
      icon: <CarOutlined />,
      label: t('menu.vehicle'),
      permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
      disableMobile: true
    },
    {
      key: PATH.CC_SETTING.substring(1),
      icon: <DatabaseOutlined />,
      label: t('menu.setting'),
      permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
      disableDesktop: true
    },
    { 
      key: PATH.CC_ADMIN.substring(1), 
      icon: <SettingOutlined />,  
      label: t('menu.admin'),
      labelMobile: t('menu.admin_mobile'),
      permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
    },
    // Waiting for approve
    // { 
		// 	key: PATH.CC_REPORT.substring(1), 
		// 	icon: <FileDoneOutlined />, 
		// 	label: t('menu.report'),
    //   permission: (userType == USER_TYPE.COLLECTING_CENTER || isAdmin).toString(),
    //   disableMobile: true
		// },

    // officer menu
    { 
      key: PATH.OFFICER_USERS.substring(1),
      icon: <UserOutlined />, 
      label: t('menu.user'),
      permission: (userGroup.includes(OFFICER_PERMISSION.MANAGER) || isAdmin).toString(),
      disableMobile: true
    },
    { 
			key: PATH.OFFICER_REPORTS.substring(1), 
			icon: <FileDoneOutlined />, 
			label: t('menu.report'),
      permission: (userGroup.includes(OFFICER_PERMISSION.REPORT) || isAdmin).toString(),
      disableMobile: true
		},

    // factory menu
    {
      key: PATH.FACTORY_DELIVERY_ORDER.substring(1),
      icon: <FileDoneOutlined />,  
      label: t('menu.delivery_order'),
      permission: (userType == USER_TYPE.FACTORY || isAdmin).toString(),
      disableMobile: true
    },
    {
      key: PATH.FACTORY_SHIPPING_INSTRUCTION.substring(1),
      icon: <LogoutOutlined />,  
      label: t('menu.si'),
      permission: (userType == USER_TYPE.FACTORY || isAdmin).toString(),
      disableMobile: true
    },
    // Factory Customer SI
    {
      key: PATH.FACTORY_CUSTOMER.substring(1),
      icon: <UserOutlined />,  
      label: t('menu.customer'),
      permission: (userType == USER_TYPE.FACTORY || isAdmin).toString(),
      disableMobile: true
    },
    {
      key: PATH.FACTORY_PROFILE.substring(1),
      icon: <ShopOutlined />,  
      label: t('menu.profile_factory'),
      permission: (userType == USER_TYPE.FACTORY || isAdmin).toString(),
      disableMobile: true
    },
    { 
			key: PATH.FACTORY_REPORT.substring(1), 
			icon: <FileDoneOutlined />, 
			label: t('menu.report'),
      permission: (userType == USER_TYPE.FACTORY || isAdmin).toString(),
      disableMobile: true
		},
    
    // farmer menu
    {
      key: PATH.MY_PROFILE.substring(1),
      icon: <SolutionOutlined />,
      label: t('menu.profile'),
      permission: (userType == USER_TYPE.FARMER).toString(),
      disableMobile: false,
      disableDesktop: true,
    },
    {
      key: PATH.MY_PLANTATIONS.substring(1),
      icon: <FileProtectOutlined />,
      label: t('menu.plantations'),
      permission: (userType == USER_TYPE.FARMER).toString(),
      disableMobile: false,
      disableDesktop: true,
    }
  ];

  const onMenuSelected = (e) => {
    if (e.key !== current) {
      setCurrent(e.key);
      navigate(`/${e.key}`);
    }
  }

  const defaultRouting = () => {
    if (userType == USER_TYPE.COLLECTING_CENTER){
      // navigate(PATH.SCALE); สำหรับ election ตาชั่ง
      navigate(PATH.TRANSACTION);
    } else if (userType == USER_TYPE.FACTORY) {
      navigate(PATH.FACTORY_DELIVERY_ORDER);
    } else if (userType == USER_TYPE.FARMER) {
      navigate(PATH.MY_PROFILE);
    }else {
      if (userGroup.includes(OFFICER_PERMISSION.MANAGER) 
          || userGroup.includes(OFFICER_PERMISSION.REPORT) 
          || isAdmin) {
            navigate(PATH.OFFICER_DASHBOARD);
      } else {
        navigate(PATH.MEMBERS);
      }
      
    }
  }

  const autoRoute = () => {
    const menu = menus
      .filter(menu => menu.permission != "false")
      .find(menu => params.menu === menu.key);
    if (!menu) {
      defaultRouting();
    } else {
      setCurrent(menu.key);
    }
  }
  const open = () => {
    
    messageApi.open({
      type: 'success',
      content: t('login_success'),
    });
  };

  React.useEffect(() => {
    if (params) {
      autoRoute();
    } else {
      defaultRouting()
    }
  }, [params])

  React.useEffect(() => {
    if (userType == USER_TYPE.COLLECTING_CENTER && user.has_cashiers === true) {
      setIsCashierModalOpen(true);
    } else {
      setIsCashierModalOpen(false);
    }
  }, [user])
  
  React.useEffect(()=>{
    setCashierId(Cookies.get('id'))
  },[])
  
  return (
    <Layout>
      {contextHolder}
      <MainMenu 
        onMenuSelected={onMenuSelected}
        currentMenu={current}
        menus={menus}
        onCashierId={()=>{
         setCashierId(null)
         setIsCashierModalOpen(true)
        }}
        cashierId={cashierId}
        isLiff={userType === USER_TYPE.FARMER}
      >
        {renderContent()}
      </MainMenu>

     
      <UserCashier 
        open={(isCashierModalaOpen && !cashierId ? true : false)} 
        onClose={() => {
          open()
          setIsCashierModalOpen(false)
        }}
        onSaveID={(id)=>{
          setCashierId(id)
        }}
      />
    </Layout>
  )
}