import React from "react";
import { Button, Col, Modal, Row, theme } from "antd";
import { useUserContext } from "../hooks/UserSettingProvider";
import { REPORT_FILTER } from "../constants/string";
import Lottie from "lottie-react";
import lottieReport from "../assets/lottie_report.json"
import { FileExcelFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { URL_REPORT } from "../constants/urls";
import Header from "../components/common/Header";
import ReportModal from "../components/officer/ReportModal";

export default function FactoryReportView() {
  const [targetReport, setTargetReport] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const { t, i18n } = useTranslation();
  const { user } = useUserContext();

  const Reports = [
    {
      id: 1, 
      label: `1. ${t("reports.gm")}`, 
      detail: t("reports.gm"),
      filter: [REPORT_FILTER.APPROVE_DATE_RANGE, REPORT_FILTER.STATUS, REPORT_FILTER.PROVINCE],
      required: true,
      url: URL_REPORT.GM_REPORT,
      params : { station : user.factory }
    },
    // Waiting for approve
    // {
    //   id: 2,
    //   label: `2. ${t("reports.rubber_quota_report")}`, 
    //   detail: t("reports.rubber_quota_report"),
    //   filter: [],
    //   url: URL_REPORT.RUBBER_QUOTA_REPORT,
    //   params : { station : user.factory } 
    // }
  ]

  const {
		token: {colorInfoText}
	} = theme.useToken();

  return (
    <div>
      {targetReport && loading != null &&
				<Modal
					title={
					<>
						Generating - {targetReport.label} Excel <FileExcelFilled />
					</>}
					open={loading != null}
					style={{
						top: 20,
					}}
					closable={false}
					footer={[]}
				>
					<Lottie animationData={lottieReport} />
				</Modal>
			}

      <Header title={"Report"} />
      <Row 
        gutter={[16, 16]}
        style={{marginTop: 16, marginBottom: 16}}>
        {Reports.map(item => (
          <Col key={item.id} xl={8} sm={12} xs={24}>
            <Button
              style={{width: "100%", textAlign: "left", borderColor: colorInfoText}}
              disabled={item.url ? false : true}
              onClick={() => setTargetReport(item)}>
                {item.label}
            </Button>
          </Col>
        ))}
      </Row>

      {/* Modal */}
			<ReportModal
				namePrefix={targetReport ? targetReport.label : null}
				open={targetReport ? true : false}
				data={targetReport}
				onStartLoading={() => setLoading(true)}
				onEndLoading={() => setLoading(null)}
				onClose={() => setTargetReport(null)}/>
        
    </div> 
  )
}
