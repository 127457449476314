import { Alert, Form, Input, InputNumber, message, Modal, Popconfirm, Select, theme } from 'antd';
import React from 'react';
import Header from '../common/Header';
import { URL_CUSTOMER } from '../../constants/urls';
import { DELETE, POST, PUT } from '../../frameworks/HttpClient';
import { UNIT } from '../../constants/string';
import ColorButton from '../common/ColorButton';
import { useTranslation } from 'react-i18next';



export default function ProductModal(props) {
  const {
    open,
    target,
    onClose,
  } = props;

  const {
    token: { colorPrimary, colorWarning },
  } = theme.useToken();

  const isCreated = target == 'add';
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState();
  const { t, i18n } = useTranslation();

  const UNIT_OPTIONS = [
    { key: UNIT.GRAM, value: UNIT.GRAM, label: t("gram")},
    { key: UNIT.KILOGRAM, value: UNIT.KILOGRAM, label: t("kilogram")},
    { key: UNIT.TONNE, value: UNIT.TONNE, label: t("tonne") },
    { key: UNIT.LITRE, value: UNIT.LITRE, label:t("litre")},
  ]
  
  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const data = await form.validateFields();
      if (isCreated) {
        await POST(URL_CUSTOMER.PRODUCT, data);
      } else {
        await PUT(`${URL_CUSTOMER.PRODUCT}${target.id}/`, data);
      }
      onClose();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_CUSTOMER.PRODUCT}${id}/`)
      props.onClose();
      message.success(t("data_deleted_successfully"))
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (open) {
      form.resetFields();
      setErrorMessages(null);
      if (!isCreated) {
        form.setFieldsValue(target)
      }
    }
  }, [open])

  return (
    <>
      <Modal
        open={open}
        okText={t("product")}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{ loading: loading }}
        onOk={handleSubmit}
        onCancel={onClose}
        footer={
          <>
            {!isCreated && target && !target.ref &&
              <Popconfirm
                title={t("Confirm-deletion-of-the-product?")}
                onConfirm={() => handleDelete(props.target.id)}
                okText={("delete")}
                okButtonProps={{ danger: true }}
                cancelText={t("cancel")}
                placement='topRight'>
          
                <ColorButton
                  style={{ float: 'left'}}
                  type={'primary'}
                  danger >
                  {t("actions.delete_data")}
                </ColorButton>
              </Popconfirm>
            }
            <ColorButton loading={loading} onClick={props.onClose}> {t("cancel")} </ColorButton>
            <ColorButton 
              style={{background: (!isCreated ? colorWarning : colorPrimary), color : 'white'}} 
              loading={loading} 
              onClick={handleSubmit}>
                {!isCreated ? t("edit") : t("add_product")}
            </ColorButton>
          </>}
      >
        <Header title={t("product")}/>
        { errorMessages && <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/>}

        <Form form={form} style={{ marginTop: '1rem' }} labelCol={{ span: 8 }}>
          <Form.Item label={t("product_name")} name='name' rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item label={t("Opening_market")} name='price_per_unit' rules={[{ required: true }]}>
            <InputNumber
              style={{ width: '100%' }}
              addonAfter='฿'
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
          </Form.Item>
          <Form.Item
            label={t("unit")}
            name='unit'
            rules={[{ required: true }]}
            valuePropName='value'
            initialValue={UNIT.KILOGRAM}>
            <Select options={UNIT_OPTIONS}/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}